import React from "react"
import { Link } from "gatsby"

const ContactButton = () => (
  <button class="contactButton">
    <Link to="/contact">Contact us today</Link>
  </button>
)

export default ContactButton
