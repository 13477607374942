import React from "react"
import { Link } from "gatsby"
import ContactButton from "../components/ContactButton"

function WESTFaq() {
  return (
    <>
      <div
        class="sectionTitle"
        style={{ marginTop: "50px", marginBottom: "20px" }}
      >
        Frequently asked questions
      </div>
      <div class="bigText">
        <b>
          Can I take this course as an individual, or do I have to enroll with a
          team?
        </b>
        <br />
        Either works! If you do choose to enroll as an individual, you will be
        assigned to a team.
        <br />
        <br />
        <b>What kind of time commitment does this does this course require?</b>
        <br />
        At a minimum, course participants can expect to spend 2 hours per week
        doing team exercises and virtual labs. In addition, participants are
        expected to read, watch short videos and spend a little time reflecting
        on their learning. In general, students spend an average of 2 - 3 hours
        a week doing these additional activities. Finally, participants can
        expect to meet with a course instructor three times during the course
        (or more if they request to do so).
        <br />
        <br />
        WEST was designed with the full-time professional in mind, so we
        recognize that schedules can be tight and certain accommodations will
        have to be made. That being said, this course is definitely an instance
        of “you get what you give.”
        <br />
        <br />
        <b>Will my employer reimburse me for the cost of this course?</b>
        <br />
        While we can’t make any guarantees, we have seen many past participants
        receive at least partial reimbursement through their employer. We’re
        happy to provide you with any material you may need (i.e. receipts,
        coursework, schedules) to facilitate the reimbursement process.
        <br />
        <br />
        <b>Are there discount tiers available for larger teams?</b>
        <br />
        Absolutely! We offer discount tiers for larger groups. If you think your
        team may qualify,{" "}
        <Link class="gotoLink" to="/contact">
          please get in touch with us.
        </Link>
        <br />
        <br />
        <b>
          I’m an executive coach with corporate clients; can I deliver the WEST
          to my customers?
        </b>
        <br />
        Yes – the Pluralistic team can train and certify you as an{" "}
        <b>Effective Team Facilitator</b>, after which you can include the WEST
        course as part of your offers. For more information on certification,{" "}
        <Link class="gotoLink" to="/contact">
          please contact us.
        </Link>
        <br />
        <br />
        <b>Is WEST open to university students?</b>
        <br />
        While this course is recommended for working professionals, we do
        collaborate with universities to offer WEST to their students. In the
        past, a version of WEST has been offered both as a standalone, for
        credit course and as an add-on to a preexisting course. For more
        information on our work with universities,{" "}
        <Link class="gotoLink" to="/contact">
          please contact us.
        </Link>
        <br />
        <br />
      </div>
      <div
        class="sectionTitle"
        style={{ marginTop: "50px", marginBottom: "0px", textAlign: "center" }}
      >
        Still have questions?
      </div>
      <div class="bigText" style={{ textAlign: "center" }}>
        Send us a message and a member of our team will get back to you as soon
        as possible!
      </div>
      <div
        style={{ textAlign: "center", marginTop: "28px", marginBottom: "68px" }}
      >
        <ContactButton></ContactButton>
      </div>
    </>
  )
}
export default WESTFaq
