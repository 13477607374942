import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactButton from "../components/ContactButton"
import Testimonials from "../components/Testimonials"
import AllTestimonials from "../components/AllTestimonials"
import Announcement from "../components/Announcement"
import WESTFaq from "../components/WESTFaq"

const WestPage = () => (
  <Layout>
    <SEO
      title="WEST - Learning and Development Program - Pluralistic Networks"
      description="Learn how to build trust with others, increase overall efficiency, and navigate moods through WEST, our virtual training program built for today's diverse teams."
    />
    <Announcement></Announcement>
    <div class="maxWidth pagePadding" style={{ marginTop: "3px" }}>
      <div style={{ justifyContent: "center" }}>
        <div class="moodiesImg"></div>
      </div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <span class=" pageSub2" style={{ paddingLeft: "13px" }}>
          Working effectively in small teams can be hard -{" "}
          <span class="pageTitle2">but it doesn't have to be.</span>
        </span>
      </div>

      <div
        style={{
          marginTop: "60px",
          marginBottom: "5px",
        }}
        class="topLine"
      >
        &nbsp;
      </div>
      <div
        style={{
          marginBottom: "56px",
        }}
        class="bottomLine"
      >
        &nbsp;
      </div>

      <div style={{ marginTop: "50px", textAlign: "center" }}>
        <span class="pageSub2">The </span>
        <span class="pageTitle2">WEST </span>
        <span class="pageSub2">solution</span>
      </div>
      <div class="bigText" style={{ marginBottom: "50px" }}>
        <br />
        The <strong>Working Effectively in Small Teams</strong> program is a
        remote learning experience designed to quickly and efficiently teach
        people the skills they need to be effective team members and leaders. It
        consists of <strong>three components:</strong>
      </div>
      <div class="row-course">
        <div class="column-course-L">
          <img src="/images/WEST/course2.png"></img>
        </div>
        <div class="column-course-R">
          <div class="sectionTitle">1. Online training</div>
          <div
            class="bigText"
            style={{ marginTop: "15px", marginBottom: "18px" }}
          >
            Honed through our work with hundreds of companies and universities,
            the WEST program is made up of a series of self-paced training
            modules. Each module consists of a number of carefully crafted
            videos and guided readings. Together, they cover the essential
            skills required to work effectively in teams.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="column" style={{ marginTop: "60px" }}>
          <div class="sectionTitle">2. Virtual learning labs</div>
          <div
            class="bigText"
            style={{ marginTop: "15px", marginBottom: "18px" }}
          >
            People learn best by practicing. The WEST program leverages
            massively multiplayer online role-playing games (MMORPGs) to conduct
            virtual learning labs where teams can practice their new skills.
            <br />
            <br />
            Participants are broken into small groups and given virtual
            exercises to complete together, online, as a team.
          </div>
        </div>

        <div class="column" style={{ marginTop: "30px" }}>
          <Testimonials
            text="Many other programs I’ve participated in give you the theory, but
            leave it to you to apply what you’ve learned in the real world...
            With WEST, we were able to practice our learning in a safe environment that was
            realistic enough to produce real learning, but where real-life
            consequences were minimized. I strongly recommend this program to
            any individual or team that wants to increase their effectiveness at
            producing competitive outcomes in a world that’s moving increasingly
            faster, and that requires us to coordinate more and more with people
            who do not share our specific cultural backgrounds and values."
            who="David Hassell"
            role="CEO, 15Five"
          ></Testimonials>
        </div>
      </div>

      <div class="sectionTitle" style={{ marginTop: "50px" }}>
        3. An on-demand guide
      </div>
      <div class="bigText" style={{ marginTop: "15px", marginBottom: "18px" }}>
        Each participant in the course will be given access to the{" "}
        <Link class="gotoLink" to="/navigator">
          Mood Navigator
        </Link>
        &nbsp;app, an on demand resource to help navigate restrictive moods that
        commonly emerge in teams –– like resignation, distrust and overwhelm ––
        when they arise, and cultivate expansive moods –– like ambition,
        resolution, and trust.
      </div>
      <div class="processImg"></div>

      <AllTestimonials></AllTestimonials>

      <div
        style={{
          marginTop: "60px",
          marginBottom: "5px",
        }}
        class="topLine"
      >
        &nbsp;
      </div>
      <div
        style={{
          marginBottom: "56px",
        }}
        class="bottomLine"
      >
        &nbsp;
      </div>

      <WESTFaq></WESTFaq>
    </div>
    <div class="topLine">&nbsp;</div>
  </Layout>
)

export default WestPage
